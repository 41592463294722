<template>
  <v-col
    cols="12"
    xs="12"
    sm="4"
    md="3"
    lg="3"
    xl="2"
    class="dash-filter"
  >
    <v-autocomplete
      label="Período"
      v-model="period"
      :items="periods"

      dense
      outlined
      hide-details
    />
  </v-col>
</template>

<script>
import { isEqual } from './DashboardUtils'

const periods = {
  'current-day': 'de Hoje',
  'recent-day': 'um Dia',
  'recent-three-days': 'três Dias',

  'current-week': 'dessa Semana',
  'recent-week': 'uma Semana',
  'recent-three-weeks': 'três Semanas',

  'current-month': 'desse Mês',
  'recent-month': 'um Mês',
  'recent-three-months': 'três Meses',

  'current-year': 'desse Ano',
  'recent-year': 'um Ano',
  'recent-three-years': 'três Anos'
}

export default {
  props: {
    value: {}
  },

  data () {
    return {
      periods: Object.keys(periods).map((value) => ({ value, text: periods[value] })),
      period: this.value
    }
  },

  watch: {
    period (period) {
      const vm = this
      if (isEqual(period, vm.value)) return
      vm.$emit('input', period)
    },

    value (value) {
      const vm = this
      if (isEqual(vm.period, value)) return
      vm.period = value
    }
  }
}

</script>

<style lang="sass">
  .dash-filter
    padding: 2px 3px
</style>
