<template>
  <v-col
    cols="12"
    xs="12"
    sm="4"
    md="3"
    lg="3"
    xl="2"
    class="dash-filter"
  >
    <v-autocomplete
      label="Granularidade"
      v-model="granularity"
      :items="granularities"

      dense
      outlined
      hide-details
    />
  </v-col>
</template>

<script>
import { isEqual } from './DashboardUtils'

const granularities = {
  year: 'Ano',
  month: 'Mês',
  'year-month': 'Mês/Ano',
  day: 'Dia',
  'day-month': 'Dia/Mês',
  'day-year': 'Dia/Mês/Ano',
  hour: 'Hora',
  'hour-day': 'Dia/Mês/Ano Hora:Minuto',
  week: 'Semana/Ano'
}

export default {
  props: {
    value: {}
  },

  data () {
    return {
      granularities: Object.keys(granularities).map((value) => ({ value, text: granularities[value] })),
      granularity: this.value
    }
  },

  watch: {
    granularity (granularity) {
      const vm = this
      if (isEqual(granularity, vm.value)) return
      vm.$emit('input', granularity)
    },

    value (value) {
      const vm = this
      if (isEqual(vm.granularity, value)) return
      vm.granularity = value
    }
  }
}

</script>

<style lang="sass">
  .dash-filter
    padding: 2px 3px
</style>
