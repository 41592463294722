<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-view-dashboard
      </v-icon>
      <b>Relatório de Acessos</b>
      <v-spacer />

      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            dark
            icon
            @click="exportData"
          >
            <v-icon small>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <center>Baixar CSC<br>desse Gráfico</center>
      </v-tooltip>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-if="isLoading"
      />

      <chart
        v-else
        class="chart"
        :option="options"
        autoresize
      />
    </v-card-text>

    <v-divider class="mt-7" />

    <v-card-actions>
      <v-row
        style="flex-wrap: wrap;"
        class="pa-2"
      >
        <DashboardFilterPeriod v-model="period" />
        <DashboardFilterRegions v-model="regions" />
        <DashboardFilterCores v-model="cores" />
        <DashboardFilterGranularity v-model="granularity" />
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import chart, { toLinesOptions } from '@/components/chart'
import API, { requests } from '@/plugins/Amplify'
import HeaderCard from '@/components/HeaderCard'
import DashboardFilterCores from './DashboardFilterCores'
import DashboardFilterPeriod from './DashboardFilterPeriod'
import DashboardFilterRegions from './DashboardFilterRegions'
import DashboardFilterGranularity from './DashboardFilterGranularity'
import { preparePeriod, isEqual, isEqualArray } from './DashboardUtils'
import FileSaver from 'file-saver'
import csv from 'papaparse'
import moment from 'moment-timezone'

export default {
  components: {
    chart,
    HeaderCard,
    DashboardFilterCores,
    DashboardFilterPeriod,
    DashboardFilterRegions,
    DashboardFilterGranularity
  },

  data () {
    const option = null
    const isLoading = true
    const regions = null
    const cores = null
    const granularity = null
    const period = 'recent-three-months'
    return { option, isLoading, regions, cores, period, granularity }
  },

  mounted () {
    const vm = this
    vm.request = null
    vm.timeout = null
    vm.loadData()
  },

  beforeDestroy () {
    const vm = this
    if (vm.timeout) clearTimeout(vm.timeout)
    if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')
    vm.request = null
    vm.timeout = null
  },

  methods: {
    async exportData () {
      const vm = this
      let csvContent = ''

      const source = await vm.loadData()
      csvContent += (csvContent ? '\r\n' : '').concat(csv.unparse(source, { header: !csvContent, delimiter: ';', newline: '\r\n' }))

      FileSaver.saveAs(
        new Blob([csvContent], { type: 'text/csv;charset=utf-8' }),
        `${moment().format('YY-MM-DD HH:mm:ss')} relatório-de-acessos.csv`
      )

      vm.isLoading = false
    },

    loadData () {
      const vm = this

      return new Promise((resolve) => {
        if (vm.timeout) clearTimeout(vm.timeout)
        if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')

        let report = 'general'
        if (vm.regions?.length) report = vm.regions.length > 1 ? 'regions' : 'cores'
        if (vm.cores?.length) report = 'cores'

        let granularity = vm.granularity || 'year-month'
        if (!vm.granularity && vm.period === 'recent-three-days') granularity = 'day'
        if (!vm.granularity && vm.period === 'recent-three-weeks') granularity = 'day-month'
        if (!vm.granularity && vm.period === 'recent-three-months') granularity = 'year-month'
        if (!vm.granularity && vm.period === 'recent-three-years') granularity = 'year'

        if (!vm.granularity && vm.period === 'recent-day') granularity = 'day'
        if (!vm.granularity && vm.period === 'recent-week') granularity = 'day-month'
        if (!vm.granularity && vm.period === 'recent-month') granularity = 'day-month'
        if (!vm.granularity && vm.period === 'recent-year') granularity = 'year-month'

        if (!vm.granularity && vm.period === 'current-day') granularity = 'hour'
        if (!vm.granularity && vm.period === 'current-week') granularity = 'day'
        if (!vm.granularity && vm.period === 'current-month') granularity = 'day-month'
        if (!vm.granularity && vm.period === 'current-year') granularity = 'month'

        const { start, end } = preparePeriod(vm.period)
        const timezone = '-03:00'
        const chart = true
        vm.isLoading = true

        vm.timeout = setTimeout(() => {
          vm.request = requests.getAccessReport({
            start,
            end,
            report,
            timezone,
            chart,
            granularity,
            cores: vm.cores?.length ? vm.cores.join(',') : undefined,
            regions: vm.regions?.length ? vm.regions.join(',') : undefined
          })

          vm.request.then(({ source }) => {
            Object.assign(vm, {
              options: toLinesOptions(source),
              isLoading: false
            })
            resolve(source)
          })
        }, 1500)
      })
    }
  },

  watch: {
    cores: {
      deep: true,
      handler (newRows, oldRows) {
        if (isEqualArray(newRows, oldRows)) return
        this.loadData()
      }
    },

    regions: {
      deep: true,
      handler (newRows, oldRows) {
        if (isEqualArray(newRows, oldRows)) return
        this.loadData()
      }
    },

    granularity: {
      deep: true,
      handler (newRow, oldRow) {
        if (isEqual(newRow, oldRow)) return
        this.loadData()
      }
    },

    period: {
      deep: true,
      handler (newRow, oldRow) {
        if (isEqual(newRow, oldRow)) return
        this.loadData()
      }
    }
  }
}

</script>

<style scoped>

.chart {
  height: 65vh;
}

</style>
