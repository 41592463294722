import moment from 'moment-timezone'

export const toStr = (str) => (
  String(str || '').trim()
)

export const preparePeriod = (period) => {
  let start = moment().add(-3, 'months').startOf('month').unix()
  const end = moment().add(1, 'days').endOf('week').unix()

  if (period === 'recent-three-years') start = moment().add(-3, 'year').startOf('year').unix()
  if (period === 'recent-three-months') start = moment().add(-3, 'month').startOf('month').unix()
  if (period === 'recent-three-weeks') start = moment().add(-3, 'week').startOf('week').unix()
  if (period === 'recent-three-days') start = moment().add(-3, 'days').startOf('day').unix()

  if (period === 'recent-year') start = moment().add(-1, 'year').startOf('year').unix()
  if (period === 'recent-month') start = moment().add(-1, 'month').startOf('month').unix()
  if (period === 'recent-week') start = moment().add(-1, 'week').startOf('week').unix()
  if (period === 'recent-day') start = moment().add(-1, 'days').startOf('day').unix()

  if (period === 'current-year') start = moment().startOf('year').unix()
  if (period === 'current-month') start = moment().startOf('month').unix()
  if (period === 'current-week') start = moment().startOf('week').unix()
  if (period === 'current-day') start = moment().startOf('day').unix()

  return { start, end }
}

export const isEqualArray = (source, target) => (
  isEqual(toArray(source), toArray(target))
)

export const isEqual = (source, target) => (
  JSON.stringify(source) === JSON.stringify(target)
)

export const toArray = (val) => Array.isArray(val) ? [...val] : []
