<template>
  <v-layout>
    <v-flex>
      <DashboardChartAccess />
    </v-flex>
  </v-layout>
</template>

<script>
import DashboardChartAccess from '@/components/DashboardChartAccess'

export default {
  components: {
    DashboardChartAccess
  }
}
</script>
