<template>
  <v-col
    cols="12"
    xs="12"
    sm="4"
    md="3"
    lg="3"
    xl="2"
    class="dash-filter"
  >
    <v-autocomplete
      label="Regiões"
      v-model="values"
      :items="regions"
      :loading="loading"

      dense
      multiple
      outlined
      small-chips
      hide-details
      deletable-chips
    />
  </v-col>
</template>

<script>
import API, { requests } from '@/plugins/Amplify'
import { toArray, isEqualArray } from './DashboardUtils'

export default {
  props: {
    value: {}
  },

  data () {
    return {
      regions: [],
      search: null,
      loading: false,
      values: toArray(this.value)
    }
  },

  mounted () {
    const vm = this
    vm.request = null
    vm.timeout = null
    vm.loadRegions()
  },

  beforeCreate () {
    const vm = this
    if (vm.timeout) clearTimeout(vm.timeout)
    if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')
  },

  methods: {
    clearTimeout () {
      const vm = this
      if (vm.timeout) clearTimeout(vm.timeout)
      if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')
    },

    loadRegions () {
      const vm = this
      vm.clearTimeout()
      if (!vm.loading) vm.loading = true

      vm.timeout = setTimeout(async () => {
        try {
          vm.request = requests.getRegions()
          const data = await vm.request
          vm.request = null
          const regions = data.map(({ id, nome }) => ({ value: id, text: nome }))
          const values = toArray(this.values)
          regions.unshift(...vm.regions.filter((region) => values.includes(region.value)))
          vm.regions = regions
        } catch (error) {
          console.error(error)
        }

        vm.loading = false
      }, 350)
    }
  },

  watch: {
    values (values) {
      const vm = this
      if (isEqualArray(values, vm.value)) return
      vm.$emit('input', Array.isArray(values) && values.length ? [...values] : null)
    },

    value (value) {
      const vm = this
      if (isEqualArray(vm.values, value)) return
      vm.values = Array.isArray(value) && value.length ? [...value] : []
    }
  }
}

</script>

<style lang="sass">
  .dash-filter
    padding: 2px 3px
    .v-input
      .v-input__control
        .v-select__slot
          .v-select__selections
            padding-top: 12px
            padding-bottom: 0
            .v-chip
              margin-bottom: 8px
</style>
