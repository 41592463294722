<template>
  <v-col
    cols="12"
    xs="6"
    sm="4"
    md="3"
    lg="2"
    xl="1"
    class="dash-filter"
  >
    <v-autocomplete
      label="Núcleos"
      v-model="values"
      :items="cores"
      :loading="loading"
      :search-input.sync="search"

      dense
      multiple
      outlined
      small-chips
      hide-details
      deletable-chips
    />
  </v-col>
</template>

<script>
import API, { requests } from '@/plugins/Amplify'
import { toStr, toArray, isEqualArray } from './DashboardUtils'

export default {
  props: {
    value: {}
  },

  data () {
    return {
      cores: [],
      search: null,
      loading: false,
      values: toArray(this.value)
    }
  },

  mounted () {
    const vm = this
    vm.request = null
    vm.timeout = null
  },

  beforeCreate () {
    const vm = this
    if (vm.timeout) clearTimeout(vm.timeout)
    if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')
  },

  methods: {
    clearTimeout () {
      const vm = this
      if (vm.timeout) clearTimeout(vm.timeout)
      if (vm.request) API.cancel(vm.request, 'CANCEL CHART ACCESS')
    },

    loadCores (params) {
      const vm = this
      vm.clearTimeout()
      if (!vm.loading) vm.loading = true

      vm.timeout = setTimeout(async () => {
        try {
          vm.request = requests.getCores(params)
          const data = await vm.request
          vm.request = null
          const cores = data.map(({ id, nucleo, regiao }) => ({ value: id, text: `${nucleo} - ${regiao}` }))
          const values = toArray(this.values)
          cores.unshift(...vm.cores.filter((core) => values.includes(core.value)))
          vm.cores = cores
        } catch (error) {
          console.error(error)
        }

        vm.loading = false
      }, 350)
    }
  },

  watch: {
    values (values) {
      const vm = this
      if (isEqualArray(values, vm.value)) return
      vm.$emit('input', Array.isArray(values) && values.length ? [...values] : null)
    },

    value (value) {
      const vm = this
      if (isEqualArray(vm.values, value)) return
      vm.values = Array.isArray(value) && value.length ? [...value] : []
    },

    search (search) {
      const vm = this
      const nome = toStr(search)
      const canSearchOnAPI = nome && !vm.cores.find(({ text }) => text === nome)
      canSearchOnAPI ? vm.loadCores({ nome }) : vm.clearTimeout()
    }
  }
}

</script>

<style lang="sass">
  .dash-filter
    padding: 2px 3px
    .v-input
      .v-input__control
        .v-select__slot
          .v-select__selections
            padding-top: 12px
            padding-bottom: 0
            .v-chip
              margin-bottom: 8px
</style>
